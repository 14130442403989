import Back from "../Back";
import ImageGallery from "react-image-gallery";

const images = [
  {
    original: "../images/atws/atws-front.jpg",
    thumbnail: "../images/atws/thumbnails/atws-front-250-250.jpg",
  },
  {
    original: "../images/atws/atws-back.jpg",
    thumbnail: "../images/atws/thumbnails/atws-back-250-250.jpg",
  },
];

const Propre = () => {
  return (
    <section className='hero' id='bio-hero'>
      <div className='hero-body'>
        <div className='container'>
          <p className='title is-2'>
            All That We Seem <small>2022-11-25</small>
          </p>
          <div className='content'>
            <div>
              <p className='title is-3'>Details</p>
              <p>
                "All That We Seem" was released in the 25th of November 2022.
                <br />
                In this work we find Moeror exploring their post-metal
                influences as well as blending heavier synths to their sound.
                <br />
                Lyrically, the band is looking inward at the flawed ideals that
                permeate our lives.
                <br />
                The album realeased in digital format, as well as limited
                edition cassette tape and 6-panel digipack CD.
              </p>
              <ImageGallery items={images} />
            </div>
            <div className='mt-6'>
              <p className='title is-3'>Listen</p>
              <div className='mt-4'>
                <p className='title is-4'>Bandcamp</p>
                <iframe
                  title='atws-bc'
                  className='bc-iframe'
                  src='https://bandcamp.com/EmbeddedPlayer/album=124219086/size=small/bgcol=333333/linkcol=0f91ff/transparent=true/'
                  seamless>
                  <a href='https://moeror.bandcamp.com/album/all-that-we-seem'>
                    All That We Seem by Moeror
                  </a>
                </iframe>
              </div>
              {/* <div className='mt-4'>
                <p className='title is-4'>Spotify</p>
                <iframe
                  style={{ borderRadius: "12px" }}
                  title='goap-spot-player'
                  src='https://open.spotify.com/embed/album/6WdOBwjBUwZjaIDGgByu5p?utm_source=generator&theme=0'
                  width='100%'
                  height='80'
                  frameBorder='0'
                  allowFullScreen={false}
                  allow='autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture'
                  loading='lazy'></iframe>
              </div> */}
              <div className='mt-4'>
                <p className='title is-4'>YouTube</p>
                <iframe
                  width='360'
                  height='240'
                  src='https://www.youtube.com/embed/V2JJ7-cb83w'
                  title='goap-yt-player'
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen={true}></iframe>
              </div>
            </div>
            <div className='mt-6'>
              <p className='title is-3'>Tracklist</p>
              <div className='table-container'>
                <table className='table is-narrow'>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Track Title</th>
                      <th>Length</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Enthralled In Eternal Bliss</td>
                      <td>06:36</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>All That We Seem</td>
                      <td>06:28</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Frailty</td>
                      <td>04:56</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>With Eyes Sewn, We Gaze Upon A Starless Sky</td>
                      <td>06:44</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Emptiness Behind The Veil</td>
                      <td>03:38</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Woven From Failure</td>
                      <td>07:16</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              All tracks published by Black Theta.
            </div>
            <div className='mt-6'>
              <p className='title is-3'>Personel</p>
              <div>
                <p>Moeror</p>
                <ul className='is-unstyled'>
                  <li>Giannis Anagnostou - Guitars, Vocals</li>
                  <li>Kostis Prassas - Guitars, Synths, Vocals</li>
                  <li>Nodas Zanos - Bass, Backing Vocals</li>
                </ul>
                <p>Guest/Session</p>
                <ul className='is-unstyled'>
                  <li>Kevin Paradis - Drums</li>
                </ul>
                <p>Miscellaneous staff</p>
                <ul className='is-unstyled'>
                  <li>
                    Kostis Prassas - Mixing, Mastering, Production, Layout
                  </li>
                  <li>Giannis Anagnostou - Artwork, Production</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Back url='/#releases-hero' />
    </section>
  );
};

export default Propre;
