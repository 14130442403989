import Back from "../Back";
import ImageGallery from 'react-image-gallery';

const images = [
  {
    original: '../images/sag/sag-front.jpg',
    thumbnail: '../images/sag/thumbnails/sag-front-250-251.jpg',
  },
]

const Sag = () => {
  return (
    <section className='hero' id='bio-hero'>
      <div className='hero-body'>
        <div className='container'>
          <p className='title is-2'>
            Sag EP <small>2019-03-10</small>
          </p>
          <div className='content'>
            <div>
              <p className='title is-3'>Details</p>
              <p>Two song debut EP, released digitally via bandcamp.</p>
              <ImageGallery items={images} />
            </div>
            <div className="mt-6">
              <p className='title is-3'>Listen</p>
              <div className="mt-4">
                <p className='title is-4'>Bandcamp</p>
                <iframe
                  title='sag-bc-player'
                  className='bc-iframe'
                  src='https://bandcamp.com/EmbeddedPlayer/album=1107270932/size=small/bgcol=333333/linkcol=ffffff/transparent=true/'
                  seamless>
                  <a href='https://moeror.bandcamp.com/album/sag'>Sag by Moeror</a>
                </iframe>
              </div>
              <div className="mt-4">
                <p className='title is-4'>Spotify</p>
                <iframe
                  title='sag-spotify-iframe'
                  style={{ borderRadius: "12px" }}
                  src='https://open.spotify.com/embed/album/6bPQNwgULiCm00txG0HS2E?utm_source=generator&theme=0'
                  width='100%'
                  height='80'
                  frameBorder='0'
                  allow='autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture'
                  loading='lazy'></iframe>
              </div>
              <div className="mt-4">
                <p className='title is-4'>Youtube</p>
                <iframe
                  width='360'
                  height='240'
                  src='https://www.youtube.com/embed/4kw-8W_xsu8'
                  title='YouTube video player'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen={true}></iframe>
              </div>
            </div>
            <div className="mt-6">
              <p className='title is-3'>Tracklist</p>
              <div className='table-container'>
                <table className='table is-narrow'>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Track Title</th>
                      <th>Length</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Familiar Void</td>
                      <td>5:27</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Sag</td>
                      <td>5:10</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="mt-6">
              <p className='title is-3'>Personel</p>
              <div>
                <p>Moeror</p>
                <ul className='is-unstyled'>
                  <li>X. - Vocals, Lyrics</li>
                  <li>Giannis A. - Guitars, Songwriting</li>
                  <li>Nodas Z. - Bass</li>
                </ul>
                <p>Guest/Session</p>
                <ul className='is-unstyled'>
                  <li>N.D. - Drums</li>
                </ul>
                <p>Miscellaneous staff</p>
                <ul className='is-unstyled'>
                  <li>Triantafillia Vassou - Artwork</li>
                  <li>Panagiotis Kalogiros - Mixing</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Back url='/#releases-hero' />
    </section>
  );
};

export default Sag;
