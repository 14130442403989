import { useMediaQuery } from "react-responsive";
import ReleasesMobile from "./ReleasesMobile";
import ReleasesWidescreen from "./ReleasesWidescreen";

const Releases = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  if (isMobile) {
    return <ReleasesMobile />;
  } else {
    return <ReleasesWidescreen />;
  }
};

export default Releases;
