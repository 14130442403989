const Manifesto = () => {
  return (
    <section className="hero" id="manifesto-hero">
      <div className="hero-body">
        <div className="container">
          <h2 className="title is-2">Manifesto.</h2>
          <div className="content mr-6">
            <p>
              Moeror was born from the need to rebel against the inevitability
              of death, to enjoy the absurdity of life. The rebellion of human
              consciousness against an indifferent universe.
              <br />
              Modern Sisyphus; behind a desk, in a factory, trapped in a
              meaningless task, always pushing.
              <br />
              Black metal is the method, not the purpose. A perfect canvas to
              explore absolute freedom of expression.
              <br />
              Following the DIY ethic as much as possible, with the help of
              artists we respect and trust.
              <br />
              <br />
              Always self-produced, always independent.
              <br />
              <br />
              Moeror | Θ
            </p>
            <br />
            <blockquote className="py-4 mt-6 mb-2 bloc">
              Χωρίσαμε τη μέρα σε πτώματα στιγμών, σε σκοτωμένες ώρες που τις
              θάβουμε μέσα μας, μέσα στις σπηλιές του είναι μας, στις σπηλιές
              όπου γεννιέται η ελευθερία της επιθυμίας, και τις μπαζώνουμε με
              όλων των ειδών τα σκατά και τα σκουπίδια που μας πασάρουν σαν
              “αξίες”, σαν “ανάγκες”, σαν “ηθική”, σαν “πολιτισμό”.
            </blockquote> - Χρόνης Μίσσιος
          </div>
        </div>
      </div>
    </section>
  )
}

export default Manifesto;
