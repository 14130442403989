const ReleasesMobile = () => {
  return (
    <section className="hero is-fullheight" id="releases-hero">
      <div className="hero-body">
        <div className="container mr-6">
          <h2 className="title is-2">Releases.</h2>

          <div className="card is-child">
            <div className="card-content">
              <a href="/releases/atws">
                <div className="media">
                  <div className="media-left">
                      <figure className="image is-48x48">
                      <img src="images/atws/atws-front.jpg" alt="atws cover art" />
                      </figure>
                  </div>
                  <div className="media-content">
                    <p className="title is-4">All That We Seem 2022</p>
                    <p className="subtitle is-6">2022-11-25</p>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div className="card is-child">
            <div className="card-content">
              <a href="/releases/mhsk">
                <div className="media">
                  <div className="media-left">
                      <figure className="image is-48x48">
                      <img src="images/mhsk/mhsk-split-front.png" alt="mhsk split cover art" />
                      </figure>
                  </div>
                  <div className="media-content">
                    <p className="title is-4">Moeror/Human Serpent/Kvadrat Split 2021</p>
                    <p className="subtitle is-6">2021-08-22, split</p>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div className="card is-child">
            <div className="card-content">
              <a href="/releases/goap">
                <div className="media">
                  <div className="media-left">
                      <figure className="image is-48x48">
                      <img src="images/goap/goap-front.png" alt="the ghosts of amour propre cover art" />
                      </figure>
                  </div>
                  <div className="media-content">
                    <p className="title is-4">The Ghosts of Amour Propre</p>
                    <p className="subtitle is-6">2020-11-25, album</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="card is-child">
            <div className="card-content">
              <a href="/releases/sag">
                <div className="media">
                    <div className="media-left">
                        <figure className="image is-48x48">
                          <img src="images/sag/sag-front.jpg" alt="sag cover art" />
                        </figure>
                    </div>
                  <div className="media-content">
                    <p className="title is-4">Sag</p>
                    <p className="subtitle is-6">2019-03-10, ep</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ReleasesMobile;