import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faSpotify,
  faBandcamp,
} from "@fortawesome/free-brands-svg-icons";
import ImageGallery from "react-image-gallery";

const images = [
  {
    original: "../images/band/2022-2.JPG",
    thumbnail: "../images/band/thumbnails/2022-2-thumb.JPG",
  },
  {
    original: "../images/band/2022-4.JPG",
    thumbnail: "../images/band/thumbnails/2022-2-thumb.JPG",
  },
  {
    original: "../images/band/2022-12.JPG",
    thumbnail: "../images/band/thumbnails/2022-2-thumb.JPG",
  },
];

const bandcampUrl = "https://moeror.bandcamp.com";
const facebookUrl = "https://www.facebook.com/moeror.official/";
const instaUrl = "https://www.instagram.com/moeror.official/";
const spotifyUrl = "https://open.spotify.com/artist/4PGEBxcXXnww5cmpqW7YLY";

const downloadEpk = () => {
  let url = 'https://moeror.com';
  if (process.env.NODE_ENV !== 'production') {
    url = 'http://localhost:3000'
  }

  fetch(url + "/epk-files/moeror-epk.zip").then((response) => {
    response.blob().then((blob) => {
      const fileURL = window.URL.createObjectURL(blob);
      let alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = "moeror-epk.zip";
      alink.click();
    });
  });
};

const Epk = () => {
  let fbIcon = <FontAwesomeIcon icon={faFacebookF} />;
  let instaIcon = <FontAwesomeIcon icon={faInstagram} />;
  let spotifyIcon = <FontAwesomeIcon icon={faSpotify} />;
  let bandcampIcon = <FontAwesomeIcon icon={faBandcamp} />;

  return (
    <div className='container mt-6'>
      <p className='title is-2'>MOEROR EPK.</p>
      <div className='mb-4'>
        <a id='downloadBtn' onClick={downloadEpk} href='#dl'>
          Download press kit photos
        </a>
      </div>
      <div className='columns'>
        <div className='column' id='bio'>
          <p className='title is-2'>Bio.</p>
          <div className='content'>
            <p className='has-text-justified'>
              Moeror, founded by Giannis, Nodas and X, recorded and released
              their debut EP <b>"Sag"</b>, on digital format via Bandcamp in
              2019. Dark and personal, this first EP a mix of dissonant noise,
              melancholic melodies and black metal riffing.
              <br />
              In 2019, Kostis (Guitar, Synths) joined the band and they started
              wokring on their debut album <b>"The Ghosts of Amour Propre"</b>,
              released in November 25 2020. The album takes a critical approach
              to the human condition on top of dense musical compositions,
              exploring a broader range of sounds.
              <br />
              It received very positive feedback by press and listeners alike.
            </p>
            <blockquote className='has-text-grey'>
              "It's bold and carries an avant-garde spirit without succumbing to
              the need to push the envelope beyond recognition. The results are
              impressive without being overly flashy, as the band are more
              focused on creating great music than trying to paint outside the
              lines of the sake of being different." - Heavy Blog is Heavy,
              07/12/2020
            </blockquote>
            <blockquote className='has-text-grey'>
              "[...] MOEROR invite us to follow them on an exciting musical
              journey full of emotions and experimentation. The band in each
              composition shows a passionate tendency to create dark and
              lifeless atmospheric modern landscapes." - thegallery.gr
              21/01/2021
            </blockquote>
            <p className='has-text-justified'>
              In 2021, Moeror participated with 2 new tracks in a split album
              with Human Serpent and Kvadrat.
              <br />
              All funds gathered by this release were used to provide support to
              the animals affected by the Greek wildfires of 2021, covering
              food, medical care and early adoption costs.
            </p>
            <blockquote className='has-text-grey'>
              "The music is electrifying, head-hooking, and creates moments of
              tragic grandeur, but the emotional turmoil and desolation of the
              sounds are unmistakable." - No Clean Singing, 23/08/2021
            </blockquote>
            <p className='has-text-justified'>
              After the tragic passing of founding member X in 2021, the band
              took a year long hiatus. In their new album{" "}
              <b>"All That We Seem"</b>, released in the 25th of November 2022,
              we find them exploring their post-metal influences as well as
              blending heavier synths to their sound. Lyrically, the band is
              looking inward at the flawed ideals that permeate our lives.
              <br />
              The album realeased in digital format, as well as limited edition
              cassette tape and 6-panel digipack CD.
            </p>

            <div className='mb-4'>
              <p className='title is-3'>Line up.</p>
              <ul className='is-unstyled'>
                <li>Giannis Anagnostou - Guitars, Vocals</li>
                <li>Kostis Prassas - Guitars, Synths, Vocals</li>
                <li>Nodas Zanos - Bass, Backing Vocals</li>
              </ul>
            </div>

            <div className='mb-4'>
              <p className='title is-3'>Social Media.</p>
              <ul className='is-unstyled'>
                <li>
                  <a
                    className='ml-4 is-block-mobile'
                    href={bandcampUrl}
                    target='_blank'
                    rel='noreferrer'>
                    {bandcampIcon} Bandcamp
                  </a>
                </li>
                <li>
                  <a
                    className='ml-4 is-block-mobile'
                    href={facebookUrl}
                    target='_blank'
                    rel='noreferrer'>
                    {fbIcon} Facebook
                  </a>
                </li>
                <li>
                  <a
                    className='ml-4 is-block-mobile'
                    href={instaUrl}
                    target='_blank'
                    rel='noreferrer'>
                    {instaIcon} Instagram
                  </a>
                </li>
                <li>
                  <a
                    className='ml-4 is-block-mobile'
                    href={spotifyUrl}
                    target='_blank'
                    rel='noreferrer'>
                    {spotifyIcon} Spotify
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='columns'>
        <div className='column' id='bio'>
          <p className='title is-2'>Media.</p>
        </div>
      </div>

      <div className='columns'>
        <div className='column' id='music'>
          <p className='title is-3'>Music.</p>
          <div className='content'>
            <p className='title is-4'>All That We Seem</p>
            <p>Listen in bandcamp:</p>
            <iframe
              title='atws-bc'
              className='bandcampbig-iframe'
              src='https://bandcamp.com/EmbeddedPlayer/album=124219086/size=large/bgcol=333333/linkcol=0f91ff/tracklist=false/transparent=true/'
              seamless>
              <a href='https://moeror.bandcamp.com/album/all-that-we-seem'>
                All That We Seem by Moeror
              </a>
            </iframe>

            <p className='title is-4 mt-4'>Moeror/Human Serpent/Kvadrat Split</p>
            <p>Listen in bandcamp:</p>
            <iframe
             title='mhsk-split-bc'
             className='bandcampbig-iframe'
              src='https://bandcamp.com/EmbeddedPlayer/album=3633545308/size=large/bgcol=333333/linkcol=e32c14/tracklist=false/transparent=true/'
              seamless>
              <a href='https://moeror.bandcamp.com/album/moeror-human-serpent-kvadrat-split-2021'>
                Moeror / Human Serpent / Kvadrat Split 2021 by Moeror, Human
                Serpent, Kvadrat
              </a>
            </iframe>

            <p className='title is-4 mt-4'>The Ghosts of Amour Propre</p>
            <p>Listen in bandcamp:</p>
            <iframe
              title='goap-bc'
              className='bandcampbig-iframe'
              src='https://bandcamp.com/EmbeddedPlayer/album=404334830/size=large/bgcol=333333/linkcol=e99708/tracklist=false/transparent=true/'
              seamless>
              <a href='https://moeror.bandcamp.com/album/the-ghosts-of-amour-propre'>
                The Ghosts of Amour Propre by Moeror
              </a>
            </iframe>

            <p className='title is-4 mt-4'>Sag</p>
            <p>Listen in bandcamp:</p>
            <iframe
              title='sag-bc'
              className='bandcampbig-iframe'
              src='https://bandcamp.com/EmbeddedPlayer/album=1107270932/size=large/bgcol=333333/linkcol=4ec5ec/tracklist=false/transparent=true/'
              seamless>
              <a href='https://moeror.bandcamp.com/album/sag'>Sag by Moeror</a>
            </iframe>
          </div>
        </div>

        <div className='column' id='videos'>
          <p className='title is-3'>Videos.</p>
          <div className='content'>
            <div className='mb-4'>
              <p className='title is-4'>All That We Seem</p>
              <iframe
                width='360'
                height='240'
                src='https://www.youtube.com/embed/V2JJ7-cb83w'
                title='goap-yt-player'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen={true}></iframe>
            </div>
            <div className='mb-4'>
              <p className='title is-4'>Cluster of Trillion Egos</p>
              <iframe
                width='360'
                height='240'
                src='https://www.youtube-nocookie.com/embed/E9Sx7aFKq34'
                title='goap-cluster-yt'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen></iframe>
            </div>
          </div>
          <p className='title is-3'>Band Photos.</p>
          <div className='content'>
            <ImageGallery items={images} />
            <div className='btnDiv mb-4'>
              You can download the images in press ready resolution by clicking
              the button below:
              <br />
              <br />
              <button
                id='downloadBtn'
                value='download'
                onClick={downloadEpk}
                className='button is-link is-small is-outlined'>
                Download press kit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Epk;
