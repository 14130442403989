import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faInstagram, faSpotify, faBandcamp } from "@fortawesome/free-brands-svg-icons"
import { useMediaQuery } from "react-responsive";

const bandcampUrl = "https://moeror.bandcamp.com";
const facebookUrl = "https://www.facebook.com/moeror.official/";
const instaUrl = "https://www.instagram.com/moeror.official/";
const spotifyUrl = "https://open.spotify.com/artist/4PGEBxcXXnww5cmpqW7YLY?si=HT0NKLwITTaz-0ZpFrLHEg";

const SocialMedia = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const isWide = useMediaQuery({ query: "(min-width: 1216px)" });

  let sizez = 'normIcon'
  if (isWide) sizez = 'bigIcon'
  if (isMobile) sizez= 'smallIcon'

  let fbIcon = <FontAwesomeIcon className={sizez} icon={faFacebookF} />
  let instaIcon = <FontAwesomeIcon className={sizez} icon={faInstagram} />
  let spotifyIcon = <FontAwesomeIcon className={sizez} icon={faSpotify} />
  let bandcampIcon = <FontAwesomeIcon className={sizez} icon={faBandcamp} />

  return (
    <div className='footer floaty-social'>
      <div className='has-text-centered'>
        <ul>
          <li>
            <a
              className='ml-4 is-block-mobile'
              href={bandcampUrl}
              target='_blank'
              rel='noreferrer'>
              {bandcampIcon}
            </a>
          </li>
          <li>
            <a
              className='ml-4 is-block-mobile'
              href={facebookUrl}
              target='_blank'
              rel='noreferrer'>
              {fbIcon}
            </a>
          </li>
          <li>
            <a
              className='ml-4 is-block-mobile'
              href={instaUrl}
              target='_blank'
              rel='noreferrer'>
              {instaIcon}
            </a>
          </li>
          <li>
            <a
              className='ml-4 is-block-mobile'
              href={spotifyUrl}
              target='_blank'
              rel='noreferrer'>
              {spotifyIcon}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SocialMedia;
