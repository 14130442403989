const Welcome = () => {
  return (
    <>
      <section className=''>
        <div className='columns is-centered mt-6'>
          <div className='has-text-centered'>
            <figure className='image hall is-inline-block'>
              <img alt='Moeror' src='images/logo/moeror-logo-white.png' />
            </figure>
          </div>
        </div>
        <div className='columns is-centered mt-6'>
          <div className='column is-half has-text-centered'>
            <p className='title is-3'>All That We Seem</p>
            <a
              className='is-block-mobile'
              href='https://moeror.bandcamp.com/album/all-that-we-seem'
              target='_blank'
              rel='noreferrer'>
              Available on Bandcamp!
            </a>
          </div>
        </div>
        <div className="columns is-centered">
          <div className="column is-half is-flex is-justify-content-center">
            <iframe
              title='atws-player-welcome'
              style={{ border: 0, width: "300px", height: "42px" }}
              src='https://bandcamp.com/EmbeddedPlayer/album=124219086/size=small/bgcol=333333/linkcol=0f91ff/transparent=true/'
              seamless>
              <a href='https://moeror.bandcamp.com/album/all-that-we-seem'>
                All That We Seem by Moeror
              </a>
            </iframe>
          </div>
        </div>
      </section>
    </>
  );
};

export default Welcome;
