import { useParallax } from "react-scroll-parallax";

const ReleasesWidescreen = () => {
  const propreParallax = useParallax<HTMLDivElement>({ speed: -7 });
  const sagParallax = useParallax<HTMLDivElement>({ speed: -5 });
  const mhskParallax = useParallax<HTMLDivElement>({ speed: -3 });

  return (
    <section className='hero is-fullheight' id='releases-hero'>
      <div className='hero-body'>
        <div className='container'>
          <div className='columns'>
            <div className='column'>
              <div className='tile is-ancestor'>
              <div className='tile is-parent' ref={mhskParallax.ref}>
                  <div className='card is-child'>
                    <a href='/releases/atws'>
                      <div className='card-image'>
                        <figure className='image'>
                          <img
                            src='images/atws/atws-front.jpg'
                            alt='all that we seem cover art'
                          />
                        </figure>
                      </div>
                    </a>
                    <div className='card-content'>
                      <div className='media'>
                        <div className='media-content'>
                          <p className='title is-4'>
                            All That We Seem 2022
                          </p>
                          <p className='subtitle is-6'>2022-11-25, album</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='tile is-parent' ref={mhskParallax.ref}>
                  <div className='card is-child'>
                    <a href='/releases/mhsk'>
                      <div className='card-image'>
                        <figure className='image'>
                          <img
                            src='images/mhsk/mhsk-split-front.png'
                            alt='mhsk split cover art'
                          />
                        </figure>
                      </div>
                    </a>
                    <div className='card-content'>
                      <div className='media'>
                        <div className='media-content'>
                          <p className='title is-4'>
                            Moeror/Human Serpent/Kvadrat 2021
                          </p>
                          <p className='subtitle is-6'>2021-08-22, split</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='tile is-parent' ref={propreParallax.ref}>
                  <div className='card is-child'>
                    <a href='/releases/goap'>
                      <div className='card-image'>
                        <figure className='image'>
                          <img
                            src='images/goap/goap-front.png'
                            alt='the ghosts of amour propre cover art'
                          />
                        </figure>
                      </div>
                    </a>
                    <div className='card-content'>
                      <div className='media'>
                        <div className='media-content'>
                          <p className='title is-4'>
                            The Ghosts of Amour Propre
                          </p>
                          <p className='subtitle is-6'>2020-11-25, album</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='tile is-parent' ref={sagParallax.ref}>
                  <div className='card is-child'>
                    <a href='/releases/sag'>
                      <div className='card-image'>
                        <figure className='image'>
                          <img
                            src='images/sag/sag-front.jpg'
                            alt='sag cover art'
                          />
                        </figure>
                      </div>
                    </a>
                    <div className='card-content'>
                      <div className='media'>
                        <div className='media-content'>
                          <p className='title is-4'>Sag</p>
                          <p className='subtitle is-6'>2019-03-10, ep</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='column is-1'>
              <h2 className='title is-2 sideways-text'>Releases.</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReleasesWidescreen;
