// import SocialMedia from "./SocialMedia";
import Welcome from "./Welcome";
import Manifesto from "./Manifesto";
import Releases from "./Releases";
import { ParallaxBanner } from "react-scroll-parallax";
import { BannerLayer } from "react-scroll-parallax/dist/components/ParallaxBanner/types";
import Bio from "./Bio";
// import NewsIndex from "./NewsIndex";
import SocialMedia from "./SocialMedia";

const background: BannerLayer = {
  image: "decomp5-0263.jpg",
  scale: [1, 2, "easeOutCubic"],
  shouldAlwaysCompleteAnimation: true,
};

const gradientOverlay: BannerLayer = {
  opacity: [0, 1],
  shouldAlwaysCompleteAnimation: true,
  expanded: false,
  children: <div className="inset gradient" />,
};

const Index = () => {
  return (
    <div>
      <ParallaxBanner
        className="paralFull"
        layers={[
          background,
          // foreground,
          gradientOverlay,
          {
            children: <Welcome />,
          },
        ]}
        style={{ aspectRatio: "2 / 1" }}
      />
      <Releases />
      {/* <NewsIndex />     */}
      <Bio />
      <Manifesto />
      <SocialMedia />
    </div>
  );
};

export default Index;
