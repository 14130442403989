import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons"
const faLeft = <FontAwesomeIcon size='3x' icon={faArrowLeft} />


const Back = ({url}: any) => {
  return (
    <div className='footer floaty-back'>
      <div className='has-text-centered'>
        <a href={`${url}`} className="is-clickable has-text-white">{faLeft}</a>
      </div>
    </div>
  );
}


export default Back;