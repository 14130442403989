import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Sag from "./components/releases/Sag";
import Propre from "./components/releases/Propre";
import MhskSplit from "./components/releases/MhskSplit";
import Index from "./components/Index";
import { ParallaxProvider } from "react-scroll-parallax";
import Bio from "./components/Bio";
// import NewsIndex from "./components/NewsIndex";
// import Post from "./components/Post";
import Atws from "./components/releases/Atws";
import Epk from "./components/EPK";

const App = () => {
  return (
    <ParallaxProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="releases">
            <Route path="sag" element={<Sag />} />
            <Route path="goap" element={<Propre />} />
            <Route path="mhsk" element={<MhskSplit />} />
            <Route path="atws" element={<Atws />} />
          </Route>
          <Route path="bio" element={<Bio/>} />
          <Route path="epk" element={<Epk/>}/>
          {/* <Route path="news" element={<NewsIndex/>} />
          <Route path="news/:id" element={<Post/>}/> */}
        </Routes>
      </BrowserRouter>
    </ParallaxProvider>
  );
}

export default App;
