import { ParallaxBanner } from "react-scroll-parallax";
import { BannerLayer } from "react-scroll-parallax/dist/components/ParallaxBanner/types";

const foreground: BannerLayer = {
  image: "brokenman.png",
  opacity: [0, 1],
  scale: [1.5, 1, "easeOutCubic"],
  shouldAlwaysCompleteAnimation: true,
};

const gradientOverlay: BannerLayer = {
  opacity: [0, 1],
  shouldAlwaysCompleteAnimation: true,
  expanded: false,
  children: <div className='inset gradient' />,
};

const Bio = () => {
  return (
    <ParallaxBanner
      className='paralFull2'
      layers={[
        foreground,
        gradientOverlay,
        {
          children: (
            <section className='hero' id='bio-hero'>
              <div className='hero-body'>
                <div className='container is-fluid mr-6'>
                  {/* <Parallax speed={-10}> */}
                  <h2 className='title is-2'>Bio.</h2>
                  <div className='content'>
                    <p className='has-text-justified'>
                      Moeror, founded by Giannis, Nodas and X, recorded and
                      released their debut EP <b>"Sag"</b>, on digital format
                      through Bandcamp in 2019. Dark and personal, this first EP
                      a mix of dissonant noise, melancholic melodies and black
                      metal riffing.<br />
                      In 2019, Kostis (Guitar, Synths) joined the
                      band and they started wokring on their debut album
                      <b>"The Ghosts of Amour Propre"</b>, released in November
                      25 2020. The album takes a critical approach to the human
                      condition on top of dense musical compositions, exploring
                      a broader range of sounds.<br />
                      It received very positive feedback by press and listeners alike.
                    </p>
                    <blockquote>
                      "It's bold and carries an avant-garde spirit without
                      succumbing to the need to push the envelope beyond
                      recognition. The results are impressive without being
                      overly flashy, as the band are more focused on creating
                      great music than trying to paint outside the lines of the
                      sake of being different." - Heavy Blog is Heavy,
                      07/12/2020
                    </blockquote>
                    <blockquote>
                      "[...] MOEROR invite us to follow them on an exciting
                      musical journey full of emotions and experimentation. The
                      band in each composition shows a passionate tendency to
                      create dark and lifeless atmospheric modern landscapes." -
                      thegallery.gr 21/01/2021
                    </blockquote>
                    <p className='has-text-justified'>
                      In 2021, Moeror participated with 2 new tracks in a split
                      album with Human Serpent and Kvadrat.<br />
                      All funds gathered by this release were used to provide support to the
                      animals affected by the Greek wildfires of 2021, covering food, medical care and early adoption costs.
                    </p>
                    <blockquote>
                      "The music is electrifying, head-hooking, and creates
                      moments of tragic grandeur, but the emotional turmoil and
                      desolation of the sounds are unmistakable." - No Clean
                      Singing, 23/08/2021
                    </blockquote>
                    <p className='has-text-justified'>
                      After the tragic passing of founding member X in 2021, the
                      band took a year long hiatus. Their bnew album{" "}
                      <b>"All That We Seem"</b>, sees them exploring their
                      post-metal influences as well as blending heavier synths
                      to their sound.
                    </p>
                    <br />
                    <span className='title is-5'>Line up</span>
                    <ul style={{ listStyleType: "none" }}>
                      <li>Giannis Anagnostou - Guitars, Vocals</li>
                      <li>Kostis Prassas - Guitars, Synths, Vocals</li>
                      <li>Nodas Zanos - Bass</li>
                    </ul>
                  </div>
                  {/* </Parallax> */}
                </div>
              </div>
            </section>
          ),
        },
      ]}
      style={{ aspectRatio: "2 / 1" }}
    />
  );
};

export default Bio;
