import Back from "../Back";
import ImageGallery from "react-image-gallery";

const images = [
  {
    original: "../images/goap/goap-front.png",
    thumbnail: "../images/goap/thumbnails/goap-front-250-250.jpg",
  }
];

const Propre = () => {
  return (
    <section className='hero' id='bio-hero'>
      <div className='hero-body'>
        <div className='container'>
          <p className='title is-2'>
            The Ghosts of Amour Propre <small>2020-11-25</small>
          </p>
          <div className='content'>
            <div>
              <p className='title is-3'>Details</p>
              <p>
                Moeror's debut album "The Ghosts of Amour Propre", released in
                November 25 2020, takes a critical approach to the human
                condition on top of dense musical compositions.
                <br />
                Released in 6-panel Digipack CD, limited to 200 copies, by Black Theta.<br />
                Released in limited edition cassette tape by Azure Graal.
              </p>
              <ImageGallery items={images} />
            </div>
            <div className='mt-6'>
              <p className='title is-3'>Listen</p>
              <div className='mt-4'>
                <p className='title is-4'>Bandcamp</p>
                <iframe
                  className='bc-iframe'
                  title='goap-spot-player'
                  src='https://bandcamp.com/EmbeddedPlayer/album=404334830/size=small/bgcol=333333/linkcol=e99708/transparent=true/'
                  seamless>
                  <a href='https://moeror.bandcamp.com/album/the-ghosts-of-amour-propre'>
                    The Ghosts of Amour Propre by Moeror
                  </a>
                </iframe>
              </div>
              <div className='mt-4'>
                <p className='title is-4'>Spotify</p>
                <iframe
                  style={{ borderRadius: "12px" }}
                  title='goap-spot-player'
                  src='https://open.spotify.com/embed/album/6WdOBwjBUwZjaIDGgByu5p?utm_source=generator&theme=0'
                  width='100%'
                  height='80'
                  frameBorder='0'
                  allowFullScreen={false}
                  allow='autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture'
                  loading='lazy'></iframe>
              </div>
              <div className='mt-4'>
                <p className='title is-4'>Youtube</p>
                <iframe
                  width='360'
                  height='240'
                  src='https://www.youtube.com/embed/L5lh0GLueS4'
                  title='goap-yt-player'
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen={true}></iframe>
              </div>
            </div>
            <div className='mt-6'>
              <p className='title is-3'>Tracklist</p>
              <div className='table-container'>
                <table className='table is-narrow'>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Track Title</th>
                      <th>Length</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Urban Nihilism</td>
                      <td>04:17</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>The Ghosts of Amour Propre</td>
                      <td>04:42</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Others</td>
                      <td>05:39</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Time's Diadem</td>
                      <td>06:11</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Janus-faced Messiah</td>
                      <td>05:08</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Youth in Naphtha</td>
                      <td>04:36</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Cluster of Trillion Egos</td>
                      <td>06:05</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              All tracks published by Black Theta.
            </div>
            <div className='mt-6'>
              <p className='title is-3'>Personel</p>
              <div>
                <p>Moeror</p>
                <ul className='is-unstyled'>
                  <li>X. - Vocals</li>
                  <li>Giannis Anagnostou - Guitars</li>
                  <li>Kostis Prassas - Guitars, Synths</li>
                  <li>Nodas Zanos - Bass</li>
                </ul>
                <p>Guest/Session</p>
                <ul className='is-unstyled'>
                  <li>Kevin Paradis - Drums</li>
                </ul>
                <p>Miscellaneous staff</p>
                <ul className='is-unstyled'>
                  <li>Kostis Prassas - Mixing, Production</li>
                  <li>Giannis Anagnostou - Production</li>
                  <li>Tom Kvålsvoll - Mastering</li>
                  <li>Andreas Prassas - Cover art</li>
                  <li>Dimitris Panagiotopoulos - Layout</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Back url='/#releases-hero' />
    </section>
  );
};

export default Propre;
