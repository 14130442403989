import Back from "../Back";
import ImageGallery from 'react-image-gallery';

const images = [
  {
    original: '../images/mhsk/mhsk-split-front.png',
    thumbnail: '../images/mhsk/thumbnails/mhsk-split-front-250-250.jpg',
  },
]

const MhskSplit = () => {
    return (
      <section className="hero" id="bio-hero">
        <div className="hero-body">
          <div className="container">
            <p className="title is-2">
              Moeror/Human Serpent/Kvadrat Split <small>2021-08-22</small>
            </p>
            <div className="content">
              <div>
                <p className="title is-3">Details</p>
                <p>
                  Split album with Human Serpent and Kvadrat.
                  <br />
                  Released in 6-panel Digipack CD, limited to 200 copies via bandcamp.
                  <br />
                  <br />* All funds gathered by this release will be used to
                  provide support to the animals affected by the recent wildfires,
                  covering food, medical care and early adoption costs.
                </p>
                <ImageGallery  items={images} />
              </div>
              <div className="mt-6">
                <p className="title is-3">Listen</p>
                <iframe
                  width='360'
                  height='240'
                  src="https://www.youtube.com/embed/orRCY6TEOzA"
                  title="mhsksplit-yt-player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                <br />
                {/* // TODO add iframe */}
                <a href="https://moeror.bandcamp.com/album/moeror-human-serpent-kvadrat-split-2021">
                  Bandcamp 
                </a>
              </div>
              <div className="mt-6">
                <p className="title is-3">Tracklist</p>
                <div className="table-container">
                  <table className="table is-narrow">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Track Title</th>
                        <th>Length</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Deep Vanity in the Pulses of Emptiness</td>
                        <td>04:33</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Vestiges of a Failed Mutation</td>
                        <td>04:13</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Nature's Shroud Against Humanity</td>
                        <td>01:02</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>Shrouds of the Fall</td>
                        <td>01:58</td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>Απομόνωση</td>
                        <td>05:33</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                A co-release by Black Theta, Scars of Millions, Desolate Depths
              </div>
              <div className="mt-6">
                <p className="title is-3">Personel</p>
                <div>
                  <p>Moeror</p>
                  <ul className="is-unstyled">
                    <li>X. - Vocals</li>
                    <li>Giannis Anagnostou - Guitars</li>
                    <li>Kostis Prassas - Guitars, Synths</li>
                    <li>Nodas Zanos - Bass</li>
                  </ul>
                  <p>Guest/Session</p>
                  <ul className="is-unstyled">
                    <li>Kevin Paradis - Drums</li>
                  </ul>
                  <p>Miscellaneous staff</p>
                  <ul className="is-unstyled">
                    <li>Kostis Prassas - Mixing, Mastering, Production (on tracks 1, 2)</li>
                    <li>Giannis Anagnostou - Production (on tracks 1, 2)</li>
                    <li>Cover art, Layout by <b>Dark Ink Terrorismo</b></li>
                  </ul>
                </div>
              </div>
              </div>
          </div>
        </div>
        <Back url="/#releases-hero" />
      </section>
    )
}

export default MhskSplit;